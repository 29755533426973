import { PropsWithChildren } from "react";
import russianJournalOfCardiology from "../assets/images/Cover_RKJ_2025_mini.jpg";
import logo from "../assets/images/rcj.jpg";
import memberKleshengovAnton from "../assets/images/kleshenogov.jpg";
import memberRodionovaJulia from "../assets/images/rodionova.jpg";
import memberTaratuhinEvgeniy from "../assets/images/taratuhin.jpg";
import { Link } from "react-router-dom";

export function JournalMainPage() {
  return (
    <article>
      <h2>Российский кардиологический журнал</h2>
      <span
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 24,
          gap: 48 * 4,
        }}
      >
        <img width="150px" src={russianJournalOfCardiology} />
        <img src={logo} />
      </span>
      <p>
        Научно-практический рецензируемый{" "}
        <b>Российский кардиологический журнал</b> – официальный орган печати
        Российского кардиологического общества (РКО), научно-практический
        рецензируемый журнал. <b>Главный редактор</b> –{" "}
        <b>Шляхто Евгений Владимирович</b>, Президент РКО, академик РАН,
        профессор, директор ФГБУ «НМИЦ им. В. А. Алмазова» Минздрава России.
        Санкт-Петербург, Россия.
      </p>
      <p>
        Это научно-практический, рецензируемый журнал для кардиологов и
        терапевтов. Основная направленность издания — научные статьи,
        посвященные оригинальным и экспериментальным исследованиям, вопросам
        фармакотерапии и кардиохирургии сердечно-сосудистых заболеваний, новым
        методам диагностики.
      </p>
      <p>
        Российский кардиологический журнал выпускается с 1996г., включен в
        перечень изданий, рекомендованных для публикации статей, содержащих
        материалы диссертаций (ВАК), включен в Scopus, EBSCO, IC, DOAJ и
        Российский индекс научного цитирования (ядро), зарегистрирован в Клубе
        главных редакторов научных журналов Европейского общества кардиологов.
      </p>
      <p>
        Полнотекстовые версии всех номеров размещены на сайте Научной
        электронной библиотеки – www.elibrary.ru, сайт издательства –
        www.roscardio.ru.
      </p>
      <p>
        Сайт журнала:{" "}
        <a href="http://russjcardiol.elpub.ru/jour/index">
          http://russjcardiol.elpub.ru/jour/index
        </a>
      </p>
      <p>
        Правила для авторов:{" "}
        <a href="http://russjcardiol.elpub.ru/jour/about/submissions#authorGuidelines">
          http://russjcardiol.elpub.ru/jour/about/submissions#authorGuidelines
        </a>
      </p>
      <p>
        Подписка на журнал:{" "}
        <Link to="/subscription">http://roscardio.ru/subscription</Link>
      </p>
      <p>
        ISSN 1560-4071 (Print), ISSN 2618-7620 (Online), Формат А4, 12 номеров в
        год, установочный тираж 7 000 экз.
      </p>
      {/* <p>Журнал открытого доступа (OA, DOAJ).</p> */}
      <p>
        Зарегистрирован Комитетом РФ по печати 06.04.1998 г. Регистрационный №
        017388
      </p>
      <p>
        Новый проект Российского кардиологического журнала –{" "}
        <b>РКЖ. Образование.</b>{" "}
        <a href="https://journal.scardio.ru/">https://journal.scardio.ru/</a>
      </p>
      <p>
        Цель создания нового направления научной деятельности – способствовать
        повышению качества образования в области кардиологии среди
        специалистов-практиков и научных работников.
      </p>
      <p>
        Отдельные независимые редакционная коллегия и редакционный совет.
        Главный редактор – Дупляков Д. В.
      </p>
      <p>
        Систематические обзоры, классические обзоры, лекции, интервью на
        актуальные темы, а также результаты оригинальных исследований.
        Публикации журнала будут поддерживать широкую образовательную платформу,
        созданную Российским кардиологическим обществом для специалистов в
        различных областях кардиологической направленности.
      </p>
      <p>4 выпуска в год, в электронном виде</p>
      <p>
        Все статьи будут включены в базу Scopus и все другие базы цитирования и
        архивации, в которые входит основной журнал.
      </p>
      {/* <h3>План номеров Российского кардиологического журнала на 2021 год</h3>
      <p>Номер/Тематика/Приглашенный ответственный редактор</p>
      <ol>
        <li>
          Острая и хроническая сердечная недостаточность/Козиолова Н. А.,
          Михайлов Е. Н., Жиров И. В.
        </li>
        <li>
          Ишемическая болезнь сердца, инфаркт миокарда/Барбараш О. Л., Галявич
          А.С.
        </li>
        <li>
          Сахарный диабет, Метаболический синдром/Арутюнов Г.П., Чумакова Г.А.
        </li>
        <li>Артериальная гипертония/Кобалава Ж.Д., Недогода С.В.</li>
        <li>
          Эпидемиология и профилактика. Спортивная кардиология и
          кардиореабилитация/Лямина Н. П., Трубачева И.А.
        </li>
        <li>
          Липиды и атеросклероз. Атеротромбоз/Гринштейн Ю.И., Явелов И. С.
        </li>
        <li>Аритмии/Лебедев Д. С., Попов С. В.</li>
        <li>Кардиохирургия/Чернявский А. М.</li>
        <li>Конгресс. Психосоциальные аспекты/Таратухин Е. О.</li>
        <li>Кардиогенетика/Костарева А. А.</li>
        <li>
          Миокардиты, клапанные и некоронарогенные заболевания/Благова О. В.,
          Моисеева О. М.
        </li>
        <li>Методы исследования в кардиологии/Атьков О. Ю.</li>
      </ol>
      <h3>РКЖ. Образование</h3>
      <p>Номер/Приглашенный ответственный редактор</p>
      <p>
        1. – Загидуллин Н. Ш. 2. – Затейщиков Д. А. 3. – Явелов И. С. 4. –
        Губарева И. В.
      </p> */}
    </article>
  );
}

type JournalMemberProps = {
  name: string;
  role: string;
  image: string;
};

export function JournalMember({
  name,
  role,
  image,
  children,
}: PropsWithChildren<JournalMemberProps>) {
  return (
    <article>
      <h2>{name}</h2>
      <p>
        <b>{role}</b>
      </p>
      <article style={{ paddingTop: 12 }}>
        <img
          src={image}
          style={{ float: "left", marginRight: 8, marginTop: 4 }}
        />
        {children}
      </article>
    </article>
  );
}

export function JournalReviewers() {
  return (
    <>
      <JournalMember
        name="Евгений Олегович Таратухин"
        image={memberTaratuhinEvgeniy}
        role="Ответственный секретарь, член редакционной коллегии"
      >
        <span>
          Евгений Таратухин - член редакционной коллегии и ответственный
          секретарь Российского кардиологического журнала. Его научный и
          практический интерес лежит в междисциплинарном поле, включающем
          медицину, психологию и гуманитарные науки. Основной в его деятельности
          является медицина (диплом врача с отличием, 2006) - кардиология и
          терапия. В 2010 году защитил кандидатскую диссертацию. Являясь с 2010
          года ассистентом и затем доцентом кафедры госпитальной терапии в РНИМУ
          им. Н. И.Пирогова, он продолжил свое обучение, получив второе высшее
          образование журналиста и степень магистра культурологии (2014), а
          также немецкую степень Master of Arts по Русской культуре (Рурский
          Университет г.Бохум), а в 2016 году – степень магистра психологии. В
          настоящий момент работает в должности заведующего кафедрой биоэтики и
          международного медицинского права ЮНЕСКО международного факультета
          РНИМУ им. Н.И.Пирогова.
        </span>
        <p>
          Соединение медицины, гуманитарного знания и психологии привело Евгения
          Таратухина к междисциплинарному взгляду на науку и практику,
          включающему области психокардиологии, биопсихокультурального подхода к
          здоровью, медицинской помощи и болезням. Его текущий научный интерес
          связан с понятием «Я» человека перед лицом тяжёлого жизнеугрожающего
          состояния или медицинского вмешательства (например, операции на
          сердце). В более широком контексте его интересы включают философию и
          психологию культуры, в частности, религии, в соединении с
          психосоматическими, психонейроиммунологическими импликациями. Всё это
          неотделимо от этики отношений врач-пациент и этики здравоохранения.
        </p>
        <p>
          Евгений Таратухин имеет опыт работы в клинических исследованиях
          лекарственных средств (с 2007 года) и сертификат «Надлежащая
          клиническая практика»; автор и соавтор более чем 90 статей и 2
          монографий; соавтор учебного пособия для врачей и научный редактор
          курса лекций по госпитальной терапии; индекс Хирша по РИНЦ равен 6.
          Является автором научно-популярного издания. Обладает сертификатом о
          владении английским языком на уровне С1 (чтение - С2) Кембриджского
          языкового центра; владеет немецким языком на уровне В1, русским языком
          (родной) на профессиональном уровне.
        </p>
      </JournalMember>
      <JournalMember
        name="Родионова Юлия Валентиновна"
        role="Шеф-редактор"
        image={memberRodionovaJulia}
      >
        <span>
          Закончила МГМСУ им. Н. А.Семашко в 1997 году, ученая степень: кандидат
          медицинских наук.
        </span>
        <p>
          Работает с журналами «Российcкий кардиологический журнал» и
          «Кардиоваскулярная терапия и профилактика» с 2002 года.
        </p>
        <p>
          Квалифицированный специалист «Стратегия и тактика развития и
          продвижения научного издания международного уровня».
        </p>
        <p>Член Европейской ассоциации научных редакторов (EASE).</p>
        <p>
          Владеет иностранными языками: английским, немецким, испанским,
          итальянским, латинским.
        </p>
        <p>
          <b>Istina ResearcherID (IRID):</b> 240336971. <b>ORCID: </b>
          0000-0002-6378-6317
        </p>
        <p>
          <b>Дополнительное образование:</b>
        </p>
        <p>
          - Выпускник кафедры Истории Церкви Исторического факультета МГУ им. М.
          В. Ломоносова (2012), аспирант ИНИОН РАН (История средних веков,
          2013-2016). Область интересов – история средних веков, религиозные
          течения.
        </p>
        <p>
          - «Управление медицинским персоналом», Международный институт
          менеджмента ЛИНК
        </p>
        <p>- «Управление персоналом», Академия государственной службы</p>
        <p>
          - специализированные курсы компьютерной графики, верстки и дизайна.
        </p>
        <p>
          Координатор проекта: Национальные клинические рекомендации. Сборник/
          Под. ред. Р.Г. Оганова. 2008-2011гг, 1-4 издания (ISBN
          978-5-9901860-5-7, ISBN 978-5-9901860-3-3, ISBN 978-5-9901860-1-9).
        </p>
        <p>
          Координатор развития научных журналов: «Академия медицины и спорта»,
          «Российский журнал истории Церкви».{" "}
        </p>
        <p>
          <b>Публикации:</b>
        </p>
        <p>
          «Научное рецензирование: как стать рецензентом?» Методические
          рекомендации. 2019. 48 с. ISBN 978-5-9907556-2-8
        </p>
      </JournalMember>
    </>
  );
}

export function JournalTranslator() {
  return (
    <JournalMember
      name="Клещеногов Антон Сергеевич"
      role="Переводчик"
      image={memberKleshengovAnton}
    >
      <span>
        Клиническая ординатура по профилю 31.08.49 (Терапия), 2019-2021.
        Кемеровский Государственный Медицинский Университет.
      </span>
      <p>
        <b>ORCID:</b> 0000-0002-4831-0986
      </p>
      <p>
        <b>Документ о медицинском английском:</b> Приложение к Диплому
        специалиста 104204 4332712 – Английский язык (продвинутый уровень), 360
        час.
      </p>
      <p>
        <b>Публикации</b>
      </p>

      <ul style={{ padding: 15, paddingTop: 0, listStylePosition: "inside" }}>
        <li>
          Равочкин Н. Н., Клещеногов А. С. Экзистенциальная психология как
          вариант психологии индивидуальности. Вестник магистратуры.
          2014;12(39)IV: 90-2.
        </li>
        <li>
          Клещеногов А. С., Малышенко О. С.. Коморбидность пир анкилозирующем
          спондилите. Материалы Межрегиональной научно-практической конференции
          молодых ученых и студентов с международным
        </li>

        <li>
          Равочкин Н. Н., Клещеногов А. С. Стресс: эмоциональная составляющая.
          Молодой ученый. 2016;10:1374-6.
        </li>
        <li>
          Равочкин Н. Н., Клещеногов А. С. Синдром выгорания как следствие
          профессиональных стрессов медицинских работников. Мир педагогики и
          психологии. 2017;3(8) Март.
        </li>
        <li>
          Кочергин Н. А., Кочергина А. М., Килина И. Р., Клещеногов А. С.,
          Леонова В. О. Возможность использования мобильного приложения в
          качестве инструмента повышения приверженности пациентов
          кардиологического профиля. Врач и информационные технологии.
          2017;2:73-80.
        </li>
        <li>
          Кочергин Н. А., Кочергина А. М., Клещеногов А. С., Леонова В. О.,
          Окунев И. М. Частота АГ и других факторов сердечно-сосудистого риска у
          студентов медицинского университета (исследование в рамках
          международного проекта «МММ17»), Российский национальный конгресс
          кардиологов 2017 (с международным участием) 24.10.2017 –
          27.10.2017.Санкт-Петербург, Россия.
        </li>
        <li>
          Кочергин Н. А., Кочергина А. М., Клещеногов А. С., Леонова В. О.,
          Окунев И. М. Мобильное приложение как инструмент вторичной
          профилактики в кардиологии, Российский национальный конгресс
          кардиологов 2017 (с международным участием) 24.10.2017 –
          27.10.2017.Санкт-Петербург, Россия.
        </li>
        <li>
          Кочергина А. М., Леонова В. О., Рубаненко О. А., Рубаненко А. О.,
          Окунев И. М., Килина И. Р., Клещеногов А. С. Факторы риска
          сердечно-сосудистых заболеваний у студентов медицинского университета
          (исследование в рамках международного проекта «MMM17»). Медицина в
          Кузбассе. 2018;17(1):39-43.
        </li>
      </ul>
    </JournalMember>
  );
}

function JournalContact({
  name,
  role,
  phone,
  email,
  children,
}: PropsWithChildren<{
  name: string;
  role: string;
  phone?: string;
  email: string;
}>) {
  return (
    <>
      <p>
        <b>{role}</b> <i>{name}</i>
      </p>
      {children && <p>{children}</p>}
      <p>
        {phone && `${phone}; `}email: <a href={email}>{email}</a>
      </p>
    </>
  );
}

export function JournalContacts() {
  return (
    <article>
      <JournalContact
        name="Родионова Юлия"
        role="Шеф-редактор"
        phone="+7-985-768-43-18"
        email="cardiojournal@yandex.ru"
      />
      <JournalContact
        name="Гусева Анна"
        role="Отдел распространения"
        email="guseva.silicea@yandex.ru"
      />
      <JournalContact
        name="Абросимова Алина"
        role="Отдел рекламы, размещение дополнительных материалов"
        phone="+7 (812) 702-37-49 доб. 005543"
        email="partners@scardio.ru"
      >
        Менеджер по работе с партнерами Российского кардиологического общества
      </JournalContact>
    </article>
  );
}

export function JournalArticlePreparation() {
  return (
    <article>
      <p>
        <i>Срочная публикация</i> (Fast Track): одобренная рецензентами и
        редакционной коллегией статья выходит в свет в ближайшем номере журнала.
      </p>
      <p>
        <i>Предпечатная публикация статьи </i>(Ahead of Print, «Принято в
        печать» в электронном виде) (с присвоением doi и размещением на сайте,
        без указания страниц; статья в печатном виде выходит в любом из будущих
        номеров).
      </p>
      <p>
        <i>Ускоренное рецензирование</i>: осуществляется в сроки, установленные
        внутренней политикой журнала.
      </p>
      <p>Публикация в журнале для авторов бесплатна.</p>
      <p>
        Редакция не взимает плату с авторов за подготовку материала к публикации
        (рассмотрение, рецензирование, редактирование, корректирование,
        макетирование).
      </p>
      <p>
        <b>
          Оплата за публикацию (после рецензирования) взимается в следующих
          случаях:
        </b>
      </p>
      <ol>
        <li>
          Если в статье в разделе "Финансирование" указывается грант и иные виды
          спонсорства.
        </li>
        <li>
          Если авторы хотят опубликовать статью в номере журнала раньше, чем
          определено планом публикаций.
        </li>
        <li>
          Если авторы хотят опубликовать статью в электронном виде в разделе
          "Принято в печать".
        </li>
      </ol>
      <p>
        Для получения авторского экземпляра обязательным условием является
        оформление годовой подписки на журнал.
      </p>
      <p>
        <b>Услуги журнала:</b>{" "}
        <a href="https://scardio.ru/izdaniya/rossiyskiy_kardiologicheskiy_zhurnal/uslugi/">
          https://scardio.ru/izdaniya/rossiyskiy_kardiologicheskiy_zhurnal/uslugi/
        </a>{" "}
      </p>
      <ol>
        <li>Ускоренное рецензирование: 25 000-00 руб</li>
        <li>
          Размещение на сайте (после рецензирования)
          <ul>
            <li>в макетированном варианте: 28 000-00 руб</li>
            <li>в простом варианте (авторский файл): 5 000-00 руб</li>
          </ul>
        </li>
        <li>Срочная публикация (после рецензирования): 33 000-00 руб</li>
        <li>
          Публикация статьи с информацией о грантовой поддержке и иных формах
          финансирования (в т.ч. «госзадание», фонд, тема НИОКР) – 70 000-00 руб
        </li>
      </ol>
    </article>
  );
}

export function JournalArticleFormatting() {
  return (
    <article>
      <h3>
        ЗАПОЛНЕННАЯ ФОРМА КОНФЛИКТА ИНТЕРЕСОВ ДЕЛАЕТСЯ ДЛЯ КАЖДОГО ИЗ АВТОРОВ И
        ПОДАЁТСЯ ОТДЕЛЬНЫМ(И) ФАЙЛОМ(АМИ) ПРИ ОТПРАВКЕ СТАТЬИ ЧЕРЕЗ САЙТ ЖУРНАЛА
      </h3>
      <p>
        <a href="#">Скачать</a>
      </p>
      <h3>
        ТРЕБОВАНИЯ НАЦИОНАЛЬНЫХ КАРДИОЛОГИЧЕСКИХ ЖУРНАЛОВ ЕВРОПЕЙСКОГО ОБЩЕСТВА
        КАРДИОЛОГОВ В ОТНОШЕНИИ РАСКРЫТИЯ КОНФЛИКТА ИНТЕРЕСОВ
      </h3>
      <p>
        <i>
          Fernando Alfonso MD PhD FESC* 1, Adam Timmis MD PhD FESC 2, Fausto J.
          Pinto MD PhD FESC 3, Giuseppe Ambrosio MD PhD FESC 4, Hugo Ector MD
          PhD FESC 5, Piotr Kulakowski MD PhD FESC 6, Panos Vardas MD PhD FESC
          7, от имени Редакторской рабочей группы Европейского Общества
          Кардиологов.
        </i>
      </p>
      <p>
        <a href="#">Резюме</a>{" "}
        <a href="#" style={{ marginLeft: "1em" }}>
          Текст
        </a>
      </p>
    </article>
  );
}
