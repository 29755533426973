import { Link } from "react-router-dom";
import russianJournalOfChurchHistory from "../assets/images/cover_RGHIC_2025_mini.jpg";

export function JournalMain() {
  return (
    <article>
      <h2 style={{ marginBottom: 24 }}>Российский журнал истории Церкви</h2>
      <img
        src={russianJournalOfChurchHistory}
        width="150px"
        style={{ float: "left", marginRight: 12 }}
      />
      <span>
        <b>Российский журнал истории Церкви</b> – независимый проект выпускников
        и сотрудников кафедры Истории Церкви Московского государственного
        университета М. В. Ломоносова (Москва), призванный освещать вопросы
        современной церковно-исторической науки и стать нейтральной площадкой
        для аспирантов, ученых, научных сотрудников равно как светских, так и
        религиозных академических учреждений.
      </span>
      <p>
        <b>Главный редактор</b> - профессор <b>Симонов Вениамин Владимирович</b>{" "}
        (Москва, Российская Федерация)
      </p>
      <p>
        Рецензируемый научно-практический журнал{" "}
        <b>
          «Российский журнал истории Церкви» (Russian Journal of Church History)
        </b>{" "}
        зарегистрирован Федеральной службой по надзору в сфере связи,
        информационных технологий и массовых коммуникаций 26 мая 2010 года
        (Свидетельство о регистрации ПИ № ФС 77-40041 — печатное издание и
        свидетельство).
      </p>
      <p>Распространение – Российская Федерация, зарубежные страны.</p>
      <p>
        Издание создается как научно-практический, рецензируемый журнал с
        гуманитарной направленностью. Российский журнал истории Церкви планирует
        издавать научные статьи и источниковые материалы, непосредственно
        относящиеся к истории, археологии и истории церковного искусства разных
        деноминаций христианства, включая западные и восточные конфессии, а
        также филологические и археографические исследования.
      </p>
      <p>
        Входит в перечень рецензируемых научных изданий, в которых
        должны быть опубликованы основные научные результаты
        диссертаций на соискание ученой степени кандидата наук, на
        соискание ученой степени доктора наук Высшей аттестационной
        комиссии (ВАК) по следующим специальностям (с 20.12.2022):
      </p>
      <ul style={{ listStyle: "none", paddingLeft: '1rem' }}>
        <li>
          5.11.2. Историческая теология (по исследовательскому
          направлению: православие, ислам, иудаизм, протестантизм)
          (теология)
        </li>
        <li>5.6.1. Отечественная история (исторические науки)</li>
        <li>5.6.2. Всеобщая история (исторические науки)</li>
        <li>
          5.6.5. Историография, источниковедение, методы исторического
          исследования (исторические науки)
        </li>
        <li>5.10.1. Теория и история культуры, искусства (философские
          науки)
        </li>
        <li>5.10.1. Теория и история культуры, искусства (культурология)
        </li>
        <li>5.10.1. Теория и история культуры, искусства (искусствоведение)
        </li>
        <li>5.10.2. Музееведение, консервация и реставрация историко-
          культурных объектов (исторические науки)
        </li>
        <li>5.10.2. Музееведение, консервация и реставрация историко-
          культурных объектов (искусствоведение)
        </li>
        <li>5.10.2. Музееведение, консервация и реставрация историко-
          культурных объектов (культурология)</li>
      </ul>
      <p>Журнал открытого доступа.</p>
      <p>
        Полнотекстовые версии всех номеров размещены на сайте Научной
        электронной библиотеки — www.elibrary.ru, сайт издательства —
        www.roscardio.ru.
      </p>
      <p>
        Сайт журнала:{" "}
        <a href="https://churchhistory.elpub.ru/jour/index">
          https://churchhistory.elpub.ru/jour/index
        </a>
      </p>
      <p>
        Правила для авторов:{" "}
        <a href="https://churchhistory.elpub.ru/jour/about/submissions#authorGuidelines">
          https://churchhistory.elpub.ru/jour/about/submissions#authorGuidelines
        </a>
      </p>
      <p>
        Подписка на журнал:{" "}
        <Link to="/subscription">http://roscardio.ru/subscription</Link>
      </p>
      <p>
        ISSN 2686-973X (Print), ISSN 2687-069X (Online), Формат 240х170, 4
        номеров в год, установочный тираж 1 000 экз.
      </p>
    </article >
  );
}

export function JournalContacts() {
  return (
    <article>
      <p>
        <b>Основной контакт:</b> <i>Бузыкина Ирина Николаевна</i>
      </p>
      <p>
        119192, Москва, Россия, Ломоносовский проспект, д. 27, стр. 4, комната
        Е-421, Кафедра Истории Церкви, исторический факультет, МГУ им. М.В.
        Ломоносова
        <br />
        <b>Тел.</b> +7-495-939-52-64 <b>e-mail: </b>{" "}
        <a href="mailto:journal@churchhistory.ru">journal@churchhistory.ru</a>
      </p>
      <p>
        <b>Дополнительный контакт:</b> <i>Родионова Юлия Валентиновна</i>
      </p>
      <p>Тел. +7-985-768-43-18</p>
    </article>
  );
}
