import { PropsWithChildren, useEffect, useState } from "react";
import cover from "../assets/images/cover_RPC_2025_mini_2.png";
import logo1 from "../assets/images/rational-pharmacotherapy-in-cardiology-logo-ru.png";
import logo2 from "../assets/images/rational-pharmacotherapy-in-cardiology-logo-en.png";
import { Link } from "react-router-dom";
import SourceFile from './RationalPharmacotherapyInCardiology.md'
import Markdown from 'react-markdown'
import MarkdownWrapper from "../common/MarkdownWrapper";

export function JournalMain() {
  const [contents, setContents] = useState<string>()
  useEffect(() => { fetch(SourceFile).then(r => r.text()).then(c => setContents(c)) }, [])
  return (
    <article>
      <h2>Рациональная Фармакотерапия в Кардиологии</h2>
      <span style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 24,
        gap: 48 * 4,
      }}
      >
        <img width="150px" src={cover} />
        <span style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
          <img src={logo1} style={{ maxWidth: 250, height: 'auto', width: 'auto' }} />
          <img src={logo2} style={{ maxWidth: 250, height: 'auto', width: 'auto' }} />
        </span>
      </span>

      <MarkdownWrapper>
        {contents}
      </MarkdownWrapper>
    </article>
  );
}


export function JournalContacts() {
  return (
    <article>
      <MarkdownWrapper>{`

**Главный редактор**: _Драпкина Оксана Михайловна_

**Ответственный секретарь редакции**: _Бутина Екатерина Кронидовна_

101990, Москва, Петроверигский пер., 10

Тел. +7(499) 553-68-10; e-mail: otsec@sticom.ru

`}</MarkdownWrapper>
    </article>
  )
}
