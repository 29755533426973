import styles from "./Footer.module.scss";
import dev from "./assets/images/dev_photo.jpg";

export function Footer() {
  return (
    <div className={styles.footer}>
      <p>
        <b>Почтовый адрес издательства:</b>
        <br />
        ООО «Силицея-Полиграф».
        <br />
        ул. Южная, 3-32, Протвино, 142281, Россия
      </p>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          textAlign: "right",
        }}
      >
        <p>
          <b>Все права защищены:</b>
          <br />
          ООО «Силицея-Полиграф» © 2025
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <p>
            <b>Разработчик сайта:</b>
          </p>
          <a
            href="https://github.com/FedorLap2006"
            style={{
              display: "flex",
              alignItems: "center",
              gap: "0.3em",
            }}
          >
            <img src={dev} style={{ height: "1.5em" }} />
            <p>Фёдор Лапшин</p>
          </a>
        </div>
      </div>
    </div>
  );
}
