import classNames from "classnames";
import React, { PropsWithChildren, StyleHTMLAttributes } from "react";
import { Link } from "react-router-dom";
import styles from "./Sidebar.module.scss";
import russianJournalOfCardiology from "./assets/images/Cover_RKJ_2025_mini.jpg";
import cardiovascularTherapyAndPrevention from "./assets/images/Cover_KVT_2025_mini.jpg";
import russianJournalOfChurchHistory from "./assets/images/cover_RGHIC_2025_mini.jpg";
import rationalPharmacotherapyInCardiology from './assets/images/cover_RPC_2025_mini_2.png';
export function Subscription({ image }: { image: string }) {
  return (
    <Link
      to="/subscription"
      className={classNames(styles.subscriptionHighlight, "forceUnhoverLink")}
    >
      <img src={image} width="100" />
      <h3>На сайте roscardio.ru открыта подписка на журнал на 2025 г.</h3>
    </Link>
  );
}

export function Sidebar({ style }: { style?: React.CSSProperties }) {
  return (
    <div
      className={styles.sidebar}
      style={{
        width: 230,
        ...style,
      }}
    >
      <Subscription image={russianJournalOfCardiology} />
      <Subscription image={cardiovascularTherapyAndPrevention} />
      <Subscription image={russianJournalOfChurchHistory} />
      <Subscription image={rationalPharmacotherapyInCardiology} />
    </div>
  );
}
